<template>
  <transition name="modal">
    <div class="modal-mask" v-show="show" @click="onClick">
      <span class="refresh">{{ refresh }}</span>
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header"></slot>
          </div>
          <div class="modal-body">
            <slot name="body"></slot>
          </div>
          <div class="modal-footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { ref, watch, onMounted, onBeforeUnmount } from "vue";
export default {
  name: "Modal",
  props: {
    show: { type: Boolean, default: false }
  },
  setup(props) {
    const refreshInterval = ref(null);
    const refresh = ref(0); //force ios12 to redraw overlay after scrolling

    const onClick = e => {
      e.stopPropagation();
    };

    onMounted(() => {
      refreshInterval.value = setInterval(() => {
        refresh.value = (refresh.value + 1) % 1000;
      }, 250);
    });
    onBeforeUnmount(() => {
      document.body.style.overflow = "";
      clearInterval(refreshInterval.value);
    });

    watch(
      () => props.show,
      v => {
        document.body.style.overflow = v ? "hidden" : "";
      }
    );
    return {
      refreshInterval,
      refresh,
      onClick
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.modal-mask {
  @include fitContainer(fixed);
  @include flexCentered(column);
  justify-content: flex-start;
  z-index: z("modal");
  background-color: rgba($color-white, 0.5);
  backdrop-filter: blur(5px);
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.refresh {
  opacity: 0;
  width: 0;
  height: 0;
}

.modal-wrapper {
  margin: addSpace(15) auto;

  @include mq($to: mobile) {
    margin-top: addSpace(5);
    margin-bottom: addSpace(20);
  }
}

.modal-container {
  @include transition;
  @include flexCentered(column);
  @include chatEdge;
  @include standardShadow;
  min-width: $size-breakpoint-tiny;
  margin: 0 auto;
  position: relative;
  z-index: z("over");
  background: $color-background;

  @include mq($to: mobile) {
    min-width: $size-breakpoint-zero;
  }
}

.modal-body {
  flex-grow: 1;
  width: 100%;
}

.modal-footer {
  @include flexCentered;
  padding: addSpace(2);
  width: 100%;
  gap: addSpace(2);
  :deep(.button) {
    width: auto;
    flex-grow: 1;
  }
}

.modal-enter-active,
.modal-leave-active {
  transition: opacity $transitions-speed, transform $transitions-speed;
}
.modal-enter,
.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
