import C from "@/assets/constants.js";

const PUBLIC = [];

const CLIENT = [
  {
    label: "Dashboard",
    key: ["/dashboard", "/"],
    icon: "dashboard"
  },
  {
    label: "Watchlist",
    key: "/watchlist",
    icon: "star"
  },
  {
    label: "Profile",
    key: "/profile",
    icon: "profile"
  }
];

const ADMIN = [
  {
    label: "Dashboard",
    key: ["/dashboard", "/"],
    icon: "dashboard"
  },
  {
    label: "Watchlist",
    key: "/watchlist",
    icon: "star"
  },
  {
    label: "Profile",
    key: "/profile",
    icon: "profile"
  }
];

export default {
  [C.ACCESS.PUBLIC]: PUBLIC,
  [C.ACCESS.CLIENT]: CLIENT,
  [C.ACCESS.ADMIN]: ADMIN
};
