<template>
  <div class="checkbox-input-field">
    <input
      type="checkbox"
      :id="lid"
      :aria-label="label"
      :name="name"
      :checked="modelValue"
      :disabled="disabled"
      @change="$emit('update:modelValue', $event.target.checked)"
    />
    <label :for="lid">
      <div class="checkbox-input-field__tick"><Icon icon="tick" /></div>
      <span class="checkbox-input-field__label"> <span v-html="label"></span> <slot></slot> </span>
    </label>
  </div>
</template>

<script>
import { computed } from "vue";
import Functions from "@/functions";
import Icon from "./Icon.vue";

export default {
  name: "CheckboxInput",
  components: { Icon },
  props: {
    name: { type: String, default: "" },
    label: { type: String, default: "" },
    disabled: { default: false, type: Boolean },
    id: { type: String, default: null },
    modelValue: { type: [String, Boolean], default: false }
  },
  emits: ["update:modelValue"],
  setup(props) {
    const lid = computed(() => props.id || Functions.Dates.generateUUID());
    return {
      lid
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.checkbox-input-field {
  margin-top: addSpace(2);
  margin-bottom: addSpace(2);

  label {
    @include flexCentered;
    cursor: pointer;
  }

  &__tick {
    @include transition();
    @include flexCentered;
    @include edge;
    flex-grow: 0;
    flex-shrink: 0;
    width: 1.25em;
    height: 1.25em;
    border: 1px solid $color-gray;
    background-color: $color-white;

    .icon {
      @include transition();
      flex-grow: 0;
      flex-shrink: 0;
      opacity: 0;
      width: 1em;
      height: 1em;
      transform: scale(0.5);
    }
  }

  &__label {
    margin-left: addSpace(2);
  }

  input {
    position: absolute;
    z-index: z("front");
    width: 1.25em;
    height: 1.25em;
    opacity: 0;
    cursor: pointer;
  }

  input:checked + label {
    .checkbox-input-field__tick {
      border-color: $color-blue;
      background-color: $color-blue;
    }

    .icon {
      opacity: 1;
      color: $color-white;
      transform: scale(1);
    }
  }

  input:disabled + label {
    cursor: unset;
    filter: saturate(0.1);
    opacity: 0.5;
  }
}
</style>
