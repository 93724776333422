<template>
  <router-link class="searchCard" :to="to">
    <div class="codeName">
      <div class="code" v-text="company.companyCode"></div>
      <div class="name" v-text="company.companyName"></div>
    </div>
    <div class="navigate">
      <Icon icon="arrow-right" />
    </div>
  </router-link>
</template>

<script>
import Functions from "@/functions";
import Icon from "@/components/atoms/Icon.vue";
export default {
  name: "SearchCard",
  props: {
    company: { type: Object, default: () => {} },
    to: { type: String, default: "" }
  },
  components: {
    Icon
  },
  setup() {
    return {
      Functions
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.searchCard {
  @include transition;
  @include edge;
  display: grid;
  grid-template-columns: 1fr 50px;
  font-size: $text-small;
  color: $color-black;
  background: $color-white;
  cursor: pointer;
  text-decoration: none;

  & > * {
    @include flexCentered(column);
    padding: addSpace(1) addSpace(2);
  }

  .codeName {
    margin-left: addSpace(1);
    overflow: hidden;
    align-items: flex-start;
  }
  .code {
    font-size: $text-body;
    font-style: italic;
  }
  .name {
    font-size: $text-tiny;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .navigate {
    @include transition;
    color: $color-blue;
  }
  &:hover:not(.header) {
    filter: brightness(95%);
    .navigate {
      transform: translateX(5px);
    }
  }
}
</style>
