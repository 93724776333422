import C from "@/assets/constants.js";

const PUBLIC = [
  {
    label: "Logout",
    key: "/logout",
    icon: "logout",
    class: "logout"
  }
];

const CLIENT = [
  {
    label: "Dashboard",
    key: "/dashboard",
    icon: "dashboard"
  },
  {
    label: "Watchlist",
    key: "/watchlist",
    icon: "star"
  },
  {
    label: "Logout",
    key: "/logout",
    icon: "logout",
    class: "logout"
  }
];

const ADMIN = [
  {
    label: "Dashboard",
    key: "/dashboard",
    icon: "dashboard"
  },
  {
    label: "Watchlist",
    key: "/watchlist",
    icon: "star"
  },
  {
    label: "Logout",
    key: "/logout",
    icon: "logout",
    class: "logout"
  }
];

export default {
  [C.ACCESS.PUBLIC]: PUBLIC,
  [C.ACCESS.CLIENT]: CLIENT,
  [C.ACCESS.ADMIN]: ADMIN
};
