<template>
  <div>
    <div class="header">
      <Search :show="showSearch" @close="toggleSearch" />
      <div class="menuPanel" :class="{ open: showMenu }" @click="toggleMenu">
        <div class="content" @click.stop="doNothing">
          <div class="close" @click="toggleMenu">
            <Icon icon="cross" />
          </div>
          <div class="user">
            <Icon class="profile" icon="profile" />
            {{ userDataValue("firstName") }}
            {{ userDataValue("lastName") }}<br />
            {{ userDataValue("email") }}
            <Button
              class="edit"
              v-bind="Functions.Presets.Button.Plain"
              icon="edit"
              to="/profile"
              @click="toggleMenu"
            />
          </div>
          <div class="items">
            <router-link
              v-for="item in menuItems"
              :key="item.key"
              class="item"
              :class="itemClasses(item)"
              :to="item.key"
              @click="toggleMenu"
            >
              <Icon v-if="item.icon" :icon="item.icon" />
              {{ item.label }}
            </router-link>
          </div>
        </div>
      </div>
      <div class="left">
        <div class="back" :class="{ visible: hasPrevious }" @click="navigation.smartBack('/')">
          <Icon icon="arrow-left" />
        </div>
        <div class="menu" @click="toggleMenu">
          <Icon icon="menu" />
        </div>
        <router-link class="logo" :to="$store.getters['User/roleHome']">
          <img alt="Soptee logo" src="@/assets/logo/spotee+color.svg" />
        </router-link>
      </div>
      <div class="center">
        <div class="btns">
          <router-link
            v-for="tab in tabItems"
            :key="JSON.stringify(tab.key)"
            class="btn"
            :class="{
              selected: isCurrent(tab.key)
            }"
            :to="Functions.Utils.arrayToString(tab.key)"
          >
            <span class="label" v-text="tab.label"></span>
          </router-link>
        </div>
        <div class="search" @click="toggleSearch">
          <Button v-bind="Functions.Presets.Button.Full" icon="magnifying-glass" />
          <div class="spotlight">
            <div class="shade"></div>
            <div class="oval"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore, mapGetters } from "vuex";
import { useRoute } from "vue-router";
import { useNavigation } from "@/components/renderless/navigation";
import useEmitter from "@/composables/useEmitter";
import _ from "lodash";
import Functions from "@/functions";
import Tabs from "@/router/tabs.js";
import Menus from "@/router/menus.js";
import Button from "@/components/atoms/Button.vue";
import Icon from "@/components/atoms/Icon.vue";
import Search from "@/components/molecules/Search.vue";
export default {
  name: "Header",
  components: {
    Button,
    Icon,
    Search
  },
  computed: {
    ...mapGetters("User", ["userDataValue"])
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const navigation = useNavigation();
    const emitter = useEmitter();
    const showMenu = ref(false);

    const menuItems = computed(() => {
      return Menus[store.getters["User/topRole"]];
    });

    const hasPrevious = computed(() => {
      return store.getters["Router/hasPrevious"](route.path);
    });

    const itemClasses = item => {
      return {
        [item.class]: true,
        current: route.path == item.key
      };
    };
    const showSearch = ref(false);
    const toggleMenu = () => {
      showMenu.value = !showMenu.value;
    };
    const toggleSearch = () => {
      showSearch.value = !showSearch.value;
    };
    const doNothing = () => {
      return false;
    };

    const tabItems = computed(() => {
      return Tabs[store.getters["User/topRole"]];
    });

    const isCurrent = key => {
      let current = false;
      let keys = key;
      if (_.isString(key)) {
        keys = [key];
      }
      keys.forEach(k => {
        if (route.path === k) {
          current = true;
        }
      });
      return current;
    };

    emitter.on("toggleSearch", () => {
      toggleSearch();
    });
    return {
      Functions,
      navigation,
      showMenu,
      menuItems,
      hasPrevious,
      itemClasses,
      showSearch,
      toggleMenu,
      toggleSearch,
      doNothing,
      tabItems,
      isCurrent
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.header {
  @include flexCentered();
  z-index: z("header");
  flex-grow: 0 !important;
  height: 40px;
  box-shadow: 0 10px 10px -10px $color-black;
  color: $color-white;
  background: $color-blue;

  .menuPanel {
    z-index: z("menu");
    @include transition;
    position: fixed;
    left: -100%;
    top: 0;
    bottom: 0;
    width: 100%;

    .content {
      position: relative;
      @include flexCentered(column);
      width: $size-breakpoint-mobile;
      height: 100%;
      max-width: 75vw;
      background: lighten($color-blue, 5%);
      box-shadow: 20px 0 40px -20px $color-black;

      .close {
        position: absolute;
        z-index: z("front");
        top: addSpace(3);
        right: addSpace(3);
        cursor: pointer;
      }

      .user {
        position: relative;
        overflow: hidden;
        width: 100%;
        text-align: center;
        padding: addSpace(20) addSpace(4);
        font-size: $text-lead;
        .profile {
          position: absolute;
          top: 0;
          left: -17%;
          width: 70%;
          opacity: 0.1;
          z-index: z("flat");
        }
        .edit {
          position: absolute;
          z-index: z("front");
          cursor: pointer;
          right: addSpace(4);
          bottom: addSpace(4);
        }
      }

      .items {
        @include flexCentered(column);
        width: 100%;
        flex-grow: 1;
        border-top: 1px solid $color-blue;
        .item {
          @include flexCentered();
          @include transition;
          justify-content: flex-start;
          width: 100%;
          padding: addSpace(3);
          background: lighten($color-blue, 5%);
          cursor: pointer;
          color: inherit;
          text-decoration: none;
          & + .item {
            border-top: 1px solid $color-blue;
          }

          &:hover {
            filter: brightness(95%);
          }
          &:active {
            filter: brightness(90%);
          }
          &.current {
            color: $color-primary;
          }

          .icon {
            margin-right: addSpace(2);
          }

          &.logout {
            margin-top: auto;
            color: $color-black;
            background: $color-error;

            .icon {
              order: 2;
              margin-left: auto;
              margin-right: 0;
            }
          }
        }
      }
    }

    &.open {
      left: 0;
    }
  }

  .left,
  .right {
    @include flexCentered();
    align-items: stretch;
    & > div {
      @include flexCentered();
      @include transition;
      font-size: 1.25em;
      cursor: pointer;
      padding: addSpace(2);
      min-width: 50px;
      height: 40px;
      &:hover {
        background: darken($color-blue, 5%);
      }
    }
  }

  .left {
    margin-right: auto;
    @include flexCentered();

    .back {
      @include transition;

      &:not(.visible) {
        font-size: 0;
        width: 0;
        min-width: 0;
        padding: 0;
      }
    }

    .logo {
      display: inline-block;
      color: inherit;
      padding: 0;
      img {
        width: 100%;
        height: 40px;
      }
    }
  }

  .center {
    @include flexCentered();
    align-self: flex-start;
    flex-grow: 1;
    height: 100%;
    padding-right: addSpace(18);

    @include mq($to: desktop) {
      display: none;
    }

    .btns {
      @include flexCentered();
      align-items: stretch;
      width: 100%;
      height: 100%;

      .btn {
        position: relative;
        overflow: hidden;
        @include transition;
        @include flexCentered(column);
        flex-grow: 1;
        height: 100%;
        cursor: pointer;
        color: inherit;
        text-decoration: none;

        & + .btn {
          border-left: 1px solid rgba($color-black, 0.3);
        }

        .label {
          @include transition;
          text-transform: uppercase;
          font-weight: 400;
          font-size: 0.85em;
        }

        &.selected {
          color: $color-light;
        }
      }
    }

    .search {
      position: absolute;
      top: addSpace(1);
      right: addSpace(1);
      &:deep(.button) {
        padding: addSpace(3);
        &:hover {
          border-radius: 2em;
        }
      }
      .spotlight {
        overflow: hidden;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        pointer-events: none;
        z-index: z("flat");
        .shade {
          position: relative;
          width: 0;
          height: 0;
          left: 25%;
          bottom: 20%;
          border-left: 22px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 45px solid rgba($color-black, 0.1);
          transform: rotate(5deg);
        }
        .oval {
          position: absolute;
          width: 60%;
          height: 20%;
          left: 20%;
          bottom: 20%;
          background: rgba($color-black, 0.1);
          border-radius: 60%;
        }
      }
    }
  }
}
</style>
