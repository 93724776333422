<template>
  <div class="searchLayer" :class="{ open: show }">
    <div class="searchBox">
      <Input
        v-bind="Functions.Presets.Field.Field"
        ref="searchField"
        v-model="searchInput"
        @update:modelValue="searchCall"
        v-on:keyup.enter="$emit('close')"
      />
      <div class="close" @click="$emit('close')">
        <Icon icon="cross" />
      </div>
      <LoadingBar class="loading" :loading="$store.getters['Company/busy']" :transparent="true" />
    </div>
    <div class="searchResults">
      <div class="help">
        <Icon icon="magnifying-glass" />
        <transition name="fade">
          <div v-if="beforeSearch">
            Type in the top searchbar to find any company
          </div>
        </transition>
        <transition name="fade">
          <div v-if="noResults">
            No company found
          </div>
        </transition>
      </div>
      <transition-group name="maxH">
        <SearchCard
          class="result"
          v-for="company in searchResults"
          :key="company.companyCode"
          :company="company"
          :to="'/company/' + company.companyCode"
          @click="$emit('close')"
        />
      </transition-group>
    </div>
  </div>
</template>

<script>
import { ref, computed, nextTick, watch, onMounted } from "vue";
import { useStore, mapGetters } from "vuex";
import { useRouter } from "vue-router";
import Functions from "@/functions";
import Icon from "@/components/atoms/Icon.vue";
import Input from "@/components/atoms/Input.vue";
import LoadingBar from "@/components/atoms/LoadingBar.vue";
import SearchCard from "@/components/atoms/SearchCard.vue";
export default {
  name: "Search",
  components: {
    Icon,
    Input,
    LoadingBar,
    SearchCard
  },
  props: {
    show: { type: Boolean, default: false }
  },
  computed: {
    ...mapGetters("Company", ["searchResults"])
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const lsContext = "Search";
    const searchField = ref(null);
    const searchInput = ref("");
    const results = ref([]);

    const searchCall = val => {
      if (val.length >= 2) {
        store.dispatch("Company/search", val);
      }
    };

    const searchEnter = () => {
      if (!store.getters["Company/busy"] && store.getters["Company/searchResults"].length > 0) {
        router.push({ path: `/company/${store.getters["Company/searchResults"][0].companyCode}` });
      }
    };

    const beforeSearch = computed(() => searchInput.value.length < 2);
    const noResults = computed(() => {
      return (
        searchInput.value.length > 2 &&
        store.getters["Company/searchResults"].length == 0 &&
        !store.getters["Company/busy"]
      );
    });

    watch(
      () => props.show,
      v => {
        if (v) {
          nextTick(() => {
            searchField.value.focus();
            searchField.value.selectContent();
          });
        }
      }
    );
    watch(
      () => searchInput.value,
      () => {
        store.dispatch("LocalSettings/setContextVar", {
          context: lsContext,
          key: "searchInput",
          value: searchInput.value
        });
        searchCall(searchInput.value);
      }
    );
    onMounted(() => {
      console.log(searchInput.value);
      searchInput.value =
        store.getters["LocalSettings/setContextVar"]({
          context: lsContext,
          key: "searchInput"
        }) || searchInput.value;
      searchCall(searchInput.value);
    });

    return {
      Functions,
      searchField,
      searchInput,
      searchCall,
      searchEnter,
      beforeSearch,
      noResults,
      results
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/style/app.scss";

.searchLayer {
  @include transition;
  @include flexCentered(column);
  z-index: z("full-page-overlay");
  position: absolute;
  pointer-events: none;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  color: $color-blue;
  background: mix($color-blue, $color-white, 15%);

  &.open {
    pointer-events: all;
    opacity: 1;
    .searchBox,
    .searchResults {
      transform: translateY(0);
    }
  }

  .searchBox {
    @include transition;
    @include flexCentered;
    position: relative;
    width: 100%;
    height: 40px;
    box-shadow: 20px 0 40px -15px $color-black;
    transform: translateY(-5px);
    :deep(.input-field) {
      &__wrapper:after {
        border-color: transparent;
      }
      &__input {
        color: $color-black;
        background: transparent;
      }
    }

    .close {
      @include transition;
      font-size: 1.25em;
      margin: 0 addSpace(3) 0 auto;
      cursor: pointer;
    }

    .loading {
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }

  .searchResults {
    @include transition;
    width: 100%;
    height: calc(100% - 40px);
    padding: addSpace(6);
    transform: translateY(-30px);
    overflow-y: auto;
    overflow-x: hidden;

    @include mq($to: mobile) {
      padding: addSpace(2);
    }

    .result {
      margin-bottom: addSpace(1);
    }

    .help {
      position: absolute;
      z-index: z("back");
      @include flexCentered;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      font-size: $text-lead;
      & > div {
        position: absolute;
        padding: addSpace(4);
        text-align: center;
      }
      .icon {
        position: absolute;
        width: 25vh;
        height: 25vh;
        filter: blur(15px);
        opacity: 0.25;
      }
    }
  }
}
</style>
